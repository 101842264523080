import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import EditorMenu from "./EditorMenu";
import styles from './MediaUpload.module.css'
import { Camera } from '../../../assets/images'
import { useTranslation } from "react-i18next";
import { LinearProgressLabel, Modal } from "../index";
import { BsCloudUpload } from "react-icons/bs";
import DynamicObject from "../../../models/dynamic-object";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { StateDataType, ImageComponentProps, } from "./MediaUpload.interfaces";
import AddMoreBox from "./AddMoreBox";
import { BoxesGap, getMediaSize, Lines, MediaBoxSize, MEDIAS_PER_LINE_ON_SCREENS } from "./MediaSettings";
import { BsFillExclamationCircleFill } from "react-icons/bs";

import UIContext from "../../../storage/UIContext";
import { FormHelperText } from "@mui/material";
import LanguageContext from "../../../storage/LanguageContext";
import MediasCacher from "../../../models/medias-cacher";
import { getDataStorage } from "../../../helpers/storage.helper";
import { getUrlBasedOnCountry } from "../../../helpers/functions";
import PostContext from "../../../storage/PostContext";
import HideCarPlateCheckbox from "../HideCarPlateCheckbox/HideCarPlateCheckbox";
import useCheckboxStore from "../../../storage/CheckboxContext";
import FlowReferences from "../../../flow-references";

interface MediaUploadProps {
    children?: React.ReactNode;
    state: StateDataType,
    addMedia: (files: FileList | null, mainImage: boolean) => Promise<boolean>
    changeMainImage: (fileHash: string) => void
    changeMedia: (files: FileList | null, oldImageHash: string) => void
    onDeleteMedia: (mediaId: string) => void,
    reOrderMedia: (currentIndex: number, newIndex: number) => void,
    maxLength: number,
    error?: string,
    mediasOrder: string[],
    config: DynamicObject,
    mediaSize: string,
    identifier: string
    data?:any
}

let mainImage: boolean
let oldImageHash: string

const LoadedMore: DynamicObject = {}
const MediaUpload = (props: MediaUploadProps): JSX.Element => {

    const [hidePlateNumber, setHidePlateNumber] = useState<boolean | null>(null);

    useEffect(() => {
        // Fetch the value from FlowReferences
        console.log("FlowReferences", FlowReferences?.get('hide_plate_number')?.state?.get())


        const flowReferenceValue = FlowReferences?.get('hide_plate_number')?.state?.get();
        if (flowReferenceValue !== undefined) {
            // Convert to boolean (1 -> true, 0 -> false)
            setHidePlateNumber(Boolean(flowReferenceValue == 1));
        }
    }, []);

    const {
        maxLength,
        state,
        addMedia,
        changeMainImage,
        changeMedia,
        onDeleteMedia,
        error,
        mediasOrder,
        identifier,
        mediaSize
    } = props
    const hashes = [...mediasOrder]
    const uploadFileRef = useRef<HTMLInputElement>(null)
    const updateFileRef = useRef<HTMLInputElement>(null)
    const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>();
    const [width, setWidth] = useState<number>(window.innerWidth);
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState<boolean>(false)
    const [isLoadedMore, setIsLoadedMore] = useState(LoadedMore[identifier] || false)
    const [isOpen, setIsOpen] = useState(false)
    const storage = getDataStorage()
    const dropRef = useRef<HTMLDivElement>(null)
    const langCtx = useContext(LanguageContext)
    const postCtx = useContext(PostContext)




    const { mediasPerLine, lines, boxSize, boxesGap } = useMemo(() => {
        return {
            mediasPerLine: MEDIAS_PER_LINE_ON_SCREENS[mediaSize],
            lines: Lines[mediaSize],
            boxSize: MediaBoxSize[mediaSize],
            boxesGap: BoxesGap[mediaSize],
        }
    }, [mediaSize])


    const onDrop = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (!e.dataTransfer.files.length)
            return
        addMedia(e.dataTransfer.files, !state.main_media).then(r => {
        })
        setIsDragging(false)
    }
    const handleDrag = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files.length)
            setIsDragging(true)
    }
    const handleDragIn = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files.length)
            setIsDragging(true)
    }
    const handleDragOut = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files.length)
            setIsDragging(false)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const uiCtx = useContext(UIContext)

    const [isBottom, setIsBottom] = React.useState(false);
    const onClickBoxHandler = (isMainImage: boolean, e: any) => {
        mainImage = isMainImage
        // if (e.target.id == 'bigContainer' || e.target.id == 'midContainer' || e.target) {
        if (Object.keys(state.medias).length < 1) {
            mainImage = true
        }
        uploadFileRef?.current?.click()
        // } else if (e.target.id == 'icon' || e.target.id == 'basic-button') {
        //     setIsBottom(true);
        // } else {
        //     setIsBottom(false);
        // }
    }
    const onClickChangeMediaHandler = (hash: string, isMainImage: boolean) => {
        mainImage = isMainImage
        oldImageHash = hash
        updateFileRef?.current?.click()
    }
    const deleteImageHandler = (e: React.MouseEvent, mediaId: string) => {
        if (e.target == e.currentTarget) {
            e.stopPropagation()
        }
        onDeleteMedia(mediaId)
    }
    const onClickMenuItemHandler = (e: React.MouseEvent, handler: () => any) => {
        if (e.target == e.currentTarget) {
            e.stopPropagation();
        }
        handler();
    }
    // const onClickAddMoreBox = () => {
    //     setIsLoadedMore(true)
    //     LoadedMore[identifier] = true
    // }
    const onSort = ({ oldIndex, newIndex }) => {
        if (oldIndex === newIndex)
            return
        props.reOrderMedia(oldIndex, newIndex)
    }
    const cameraSrc = (isMain: boolean) => {
        return Camera
    }
    const TipHandller = (event) => {
        setIsOpen(true)
    }

    useEffect(() => {
        // window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            // window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    useEffect(() => {
        if (!dropRef.current)
            return
        dropRef.current.addEventListener('drop', onDrop)
        dropRef.current.addEventListener('dragenter', handleDragIn)
        dropRef.current.addEventListener('dragleave', handleDragOut)
        dropRef.current.addEventListener('dragover', handleDrag)
        return () => {
            dropRef.current?.removeEventListener('drop', onDrop)
            dropRef.current?.removeEventListener('dragenter', handleDragIn)
            dropRef.current?.removeEventListener('dragleave', handleDragOut)
            dropRef.current?.removeEventListener('dragover', handleDrag)
        };
    }, [dropRef])

    const ImageBoxComponent = (imageBoxProps: ImageComponentProps) => {
        const { mediaKey = null, isMain = false, index, isLastBox = false } = imageBoxProps
        const src = !!mediaKey ? state.medias[mediaKey].url : cameraSrc(isMain)
        const medias = state.medias
        const isCanDelete = !(props.config.mandatory && Object.values(state.medias).length <= 1)

        const boxClassName = () => {
            let className = `${styles.box}`
            if (isMain && mediaKey)
                className = `${className} ${styles.solid_border} ${styles.z_index}`
            if (!mediaKey) {
                return `${className} cursor__pointer`
            }
            return `${className} ${styles.loaded}`
        }
        const containerProps: DynamicObject = {
            id: "bigContainer",
            className: boxClassName(),
            style: {
                width: boxSize,
                height: boxSize
            }
        }
        // if (!mediaKey)
        //     containerProps.onClick = (e: any) => onClickMenuItemHandler(e, () => onClickBoxHandler(isMain, e))
        const Component = (props: DynamicObject) => <div
            className={`${styles.box_container} image-box`} style={{}}>
            {
                isMain && <div className={`${styles.cover_image}`}>{t('coverPicture')}</div>
            }
            <div {...containerProps} {...props} >
                <div className={styles.icon}>
                    <div className={styles['image-fill']} onClick={(e: any) => {
                        if (!mediaKey)
                            onClickMenuItemHandler(e, () => onClickBoxHandler(isMain, e))
                    }} />
                    <img src={src} alt={'image'} id="midContainer" />
                </div>
                {/*{*/}
                {/*    isMain && ((mediaKey && state.medias[mediaKey].isLoaded) || !mediaKey) &&*/}
                {/*    <div className={styles.text}>{t('mainImage')}</div>*/}
                {/*}*/}
                {
                    (mediaKey && !medias[mediaKey].isLoaded) && (
                        <div className={styles["progress-container"]}>
                            <div className={styles['content']}>
                                <LinearProgressLabel variant="buffer" value={medias[mediaKey].progress}
                                    container={{ className: styles.progress }} />
                            </div>
                        </div>
                    )
                }
                {mediaKey && medias[mediaKey].isLoaded &&
                    <EditorMenu
                        mediaKey={mediaKey}
                        isMain={isMain}
                        onClickMenuItemHandler={onClickMenuItemHandler}
                        deleteImageHandler={deleteImageHandler}
                        onClickChangeMediaHandler={onClickChangeMediaHandler}
                        changeMainImage={changeMainImage}
                        state={state}
                        isCanDelete={isCanDelete}
                    />
                }
                {mediaKey && <div className={styles.count}>{index + 1}</div>}
            </div>
        </div>
        // let Container : any = () => <Component />
        //
        // if (mediaKey && !isMain)
        //      const Container = SortableElement((item) => <Component {...item}/>)
        return <Component />
    }
    const mediaCount = Object.values(state.medias).length
    const defaultOpenedMedias = (mediasPerLine * lines) - 1
    // let numberBoxesMedias = defaultOpenedMedias
    // if (mediaCount > defaultOpenedMedias)
    //     numberBoxesMedias = (Math.floor(mediaCount / mediasPerLine) * mediasPerLine) + (mediasPerLine - 1)
    // if (numberBoxesMedias > maxLength || isLoadedMore)
    //     numberBoxesMedias = maxLength
    // let mediaKeys = Object.keys(state.medias)
    const boxes = []
    const mainKey = state.main_media && state.medias[state.main_media] ? state.main_media : ''
    if (mainKey) {
        // mediaKeys = mediaKeys.filter(item => item !== mainKey)
        hashes.shift()
    }
    let MainImageComponent: any = () => <ImageBoxComponent mediaKey={mainKey} isMain={true} index={0} />
    if (mainKey) {
        MainImageComponent = SortableElement(() => <ImageBoxComponent mediaKey={mainKey} isMain={true} index={0} />)
    }
    // boxes.push(<ImageBoxComponent mediaKey={mainKey} isMain={true} index={0}/>)
    boxes.push(<MainImageComponent index={0} />)
    // mediaKeys = mediaKeys.sort(function (a, b) {
    //     let n1, n2
    //     n1 = parseInt(a.split("-", 1)[0])
    //     n2 = parseInt(b.split("-", 1)[0])
    //     return n1 - n2;
    // })
    for (let i = 1; i < maxLength; i++) {
        const mediaKey = hashes.shift()
        let Item: any = () => <ImageBoxComponent mediaKey={mediaKey} index={i} />
        if (mediaKey) {
            Item = SortableElement(() => <ImageBoxComponent mediaKey={mediaKey} index={i} />
            )
        }
        boxes.push(
            <Item index={i} />
        )
    }
    // if (!isLoadedMore && numberBoxesMedias < maxLength)
    //     boxes.push(<div className={`${styles.box_container} image-box ${styles[mediaSize]}`} style={{}}><AddMoreBox
    //         onClickAddMoreBox={onClickAddMoreBox}/></div>)
    const Images = SortableContainer(() => <div style={{
        maxWidth: `calc(${(boxSize * mediasPerLine) + ((mediasPerLine - 1) * boxesGap)}px + ((var(--bs-gutter-x) * 0.5) * 2))`,
        margin: "auto"
    }}>
        <div className={`col-12 d-flex flex-wrap ${styles.box_gap} ${boxSize == '160' ? '' : 'justify-content-center'}`}>{boxes}</div>
    </div>)
    const CSS_VARIABLES = {
        "--media-box-size": `${boxSize}px`,
        "--media-boxes-gap": `${boxesGap}px`,
    } as React.CSSProperties
    // @ts-ignore

    // set the initial state for the Hide Car Plate Checkbox
    const initializeChecked = useCheckboxStore((state) => state.initializeChecked);
    initializeChecked(props?.config?.step_checkbox?.enable_checkbox, props?.config?.step_checkbox?.text, props?.config?.step_checkbox?.identifier);

    return (
        <>
            {postCtx.data?.flow_type != 'reviews' && <div onClick={(event) => TipHandller(event)}
                className={`d-flex justify-content-center w-100 mt-1 mb-5 ${styles.tip_container}`}>
                <div className={`mt-1`}><BsFillExclamationCircleFill /></div>
                {t('tipsForGreatPictures')} </div>}

            {/* hide car plate check */}

            {
                props?.config?.step_checkbox?.enable_checkbox &&
                (
                    // Priority 1
                    postCtx?.data?.previous?.step_identifier === "init_CategoryStep" &&
                        props?.config?.identifier === "postMedia" ? (
                        <div className="pb-5 mb-5">
                            <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                        </div>
                    ) :
                        // Priority 2
                        !storage.isMobile &&
                            postCtx?.data?.previous?.step_identifier !== "init_SubCategoryStep" &&
                            props?.config?.identifier === "postMedia" &&
                            postCtx?.data?.step?.uri.includes("CarsForSale") ? (
                            <div className="pb-5 mb-5">
                                <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                            </div>
                        ) :
                            // Priority 3
                            postCtx?.data?.previous?.step_identifier === "post_reelStepIdentifier" && (
                                <div className="pb-5 mb-5">
                                    <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                                </div>
                            )
                )
            }




            {
                props?.config?.step_checkbox?.enable_checkbox &&
                (
                    // Priority 1
                    storage.isMobile &&
                        props?.config?.identifier === "postMedia" &&
                        postCtx?.data?.step?.uri.includes("CarsForSale") ? (
                        <div className="pb-4">
                            <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                        </div>
                    ) :
                        // Priority 2
                        storage.isMobile &&
                            (postCtx?.data?.previous?.step_identifier === "init_SubCategoryStepDraft" || postCtx?.data?.previous?.step_identifier === "init_SubCategoryStep") ? (
                            <div className="pb-4">
                                <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                            </div>
                        ) :
                            // Priority 3
                            (postCtx?.data?.previous?.step_identifier === "post_APF_CFS_vin_description_fieldStep" ||
                                postCtx?.data?.previous?.step_identifier === "post_APF_CFS_vin_description_fieldStep_alias" ||
                                (postCtx?.data?.previous?.step_identifier === "init_SubCategoryStep" && postCtx?.data?.step?.identifier === "post_previewStep")) && (
                                <div className="pb-4">

                                    <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                                </div>
                            )
                )
            }

            {(props?.config?.step_checkbox?.enable_checkbox)&&( (props?.data?.formType==="car_review") || (postCtx?.data?.formType === "car_review")) &&
                <div className="pb-5 mb-5">
                    <HideCarPlateCheckbox defaultChecked={hidePlateNumber} />
                </div>
            }


            {error && <div className={`d-flex w-100`}>
                <FormHelperText style={{
                    margin: "5px 0px ",
                    marginBottom: "20px",
                    paddingTop: "2px",
                    color: "black",
                    fontWeight: "700",
                    borderRadius: "5px",
                    border: "1px dashed #F14F75",
                    fontSize: "12px",
                    padding: "4px 11px 0px 11px",
                    width: "100%",
                    background: "rgba(247, 213, 222, 0.35)",
                    textAlign: langCtx.language === 'ar' ? 'right' : 'left'
                }}>

                    {` ${error}`}</FormHelperText></div>}

            <input type='file' accept='image/*' className={styles["upload-file"]} ref={uploadFileRef} multiple={true}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    addMedia(e.target.files, mainImage).then(() => {
                        e.target.value = ''
                    })
                }} />
            <input type='file' accept='image/*' className={styles["upload-file"]} ref={updateFileRef}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    changeMedia(e.target.files, oldImageHash)
                }} />
            {/*{error && <div className="alert alert-danger" role="alert">*/}
            {/*    {error}*/}
            {/*</div>}*/}
            <div className={`${styles.container} ${styles.w100} ${styles[mediaSize]}`}
                ref={dropRef}
                style={CSS_VARIABLES}
                id={'MediaImagesMainContainer'}
            >
                {
                    isDragging && <div className={styles.dragging__container}>
                        <div className={styles.dragging__box}>
                            <div className={styles.dragging__icon}>
                                <BsCloudUpload />
                            </div>
                            <div className={styles.dragging__text}>Drop & Upload</div>
                        </div>
                    </div>
                }
                <Images axis={"xy"}
                    onSortEnd={onSort}
                    // rtl={langCtx.language == "ar"}
                    shouldCancelStart={(e) => {
                        e.stopPropagation()
                        let element = e.target as HTMLElement
                        if (element instanceof SVGElement)
                            element = element.parentElement
                        if (element.classList.contains("menu-icon"))
                            return true
                        return false
                    }} />
            </div>
            {isOpen && <Modal
                setIsOpen={setIsOpen}
                styles={{
                    content: {
                        height: "100%",
                        width: "100%",
                        overflow: "hidden",
                        padding: "30px 0px"
                    }
                }}
            >
                <iframe className={`${styles.iframe_style}`}
                    src={`${getUrlBasedOnCountry(storage.country)}/${langCtx.language}/add-pictures-tips?webview=1`} title="Image Tip" />
            </Modal>}
        </>
    )
}
export default MediaUpload