import { sendLoggingInformation } from "../sendLoggingInformation";
import { DEFAULT_VALUE, EnumActionNameLevel1, EnumActionNameLevel2, EnumActionNameLevel3, EnumGeneratedBy, EnumUxTypes, Member, Post } from "../types/LoggingTypes";
import { updateActionHistory } from "./updateActionHistory";
import { updateScreenHistory } from "./updateScreenHistory";
import { updateWidgetHistory } from "./updateWidgetHistory";

const logAddListingSubmit = (post: Post) => {
  const flow = sessionStorage.getItem('loggingFlow') ?? '';
  switch(flow)
  {
    case 'add':
    case 'republish':
    case 'draft':
      break;  
    default:
      return;
  }
  updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUBMIT);
  updateWidgetHistory('')
  sendLoggingInformation({uxType: EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post: post});
}

const logAddListingSuccess = (post: Post, timeout: number)=>{
  const flow = sessionStorage.getItem('loggingFlow') ?? '';
  switch(flow)
  {
    case 'add':
    case 'republish':
    case 'draft':
      break;  
    default:
      return;
  }
  setTimeout(()=>{
    updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('')
    sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post: post});
  }, timeout)
}


const logScreenLoad = async () => {
  const flow = sessionStorage.getItem('loggingFlow') ?? '';
  switch(flow){
    case 'add':
    case 'republish':
    case 'draft':
      updateScreenHistory('add_listing_form');
      break;
    case 'edit':
      updateScreenHistory('edit_listing_form');
      break;
    default:
      return;
  }
  updateActionHistory(EnumActionNameLevel1.SCREEN, EnumActionNameLevel2.LOAD, EnumActionNameLevel3.SUCCESS);
  updateWidgetHistory('')
  await sendLoggingInformation({
    uxType: EnumUxTypes.SCREEN, 
    actionGeneratedBy:  EnumGeneratedBy.USER
  });
  
}

const logApplyCvSuccess = async (post: Post): Promise<void> => {
  await updateActionHistory(EnumActionNameLevel1.APPLY, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUCCESS);
  updateWidgetHistory('');
  await sendLoggingInformation({ // Await the logging call
      uxType: EnumUxTypes.BUTTON,
      actionGeneratedBy: EnumGeneratedBy.USER,
      post: post,
      action_reference_entity: 'post',
      action_reference_id: post.id
  });
};

const logPermissionLocationAllow = ()=>{
  updateActionHistory(EnumActionNameLevel1.PERMISSION, EnumActionNameLevel2.LOCATION, EnumActionNameLevel3.ALLOW);
  updateWidgetHistory('');
  sendLoggingInformation({actionGeneratedBy: EnumGeneratedBy.USER, uxType: EnumUxTypes.NA});
}

const logPermissionLocationDeny = ()=>{
  updateActionHistory(EnumActionNameLevel1.PERMISSION, EnumActionNameLevel2.LOCATION, EnumActionNameLevel3.DENY);
  updateWidgetHistory('');
  sendLoggingInformation({actionGeneratedBy: EnumGeneratedBy.USER, uxType: EnumUxTypes.NA});
}

const logEditListingSubmit = (post : Post)=>{
  updateActionHistory(EnumActionNameLevel1.EDIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUBMIT);
  updateWidgetHistory('');
  sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post})
}

const logEditListingSuccess = (post : Post)=>{
  updateActionHistory(EnumActionNameLevel1.EDIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUCCESS);
  updateWidgetHistory('');
  sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post})
}

const logLimitListingCategory = (post: Post, timeout: number)=>{
  updateActionHistory(EnumActionNameLevel1.LIMIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CATEGORY);
  updateWidgetHistory('');
  sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post})
}

const logLimitListingSubCategory = (post: Post, timeout: number)=>{
  updateActionHistory(EnumActionNameLevel1.LIMIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUB_CATEGORY);
  updateWidgetHistory('');
  sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post})
}

const logLimitListingAccount= (post: Post, timeout: number)=>{
  updateActionHistory(EnumActionNameLevel1.LIMIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.ACCOUNT);
  updateWidgetHistory('');
  sendLoggingInformation({uxType:EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER, post})
}

export const logAddSurveyClick = () => {
  updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.SURVEY, EnumActionNameLevel3.CLICK);
  updateWidgetHistory('');
  sendLoggingInformation({uxType: EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER})
}

export const logAddSurveySubmit = () => {
  updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.SURVEY, EnumActionNameLevel3.SUBMIT);
  updateWidgetHistory('');
  sendLoggingInformation({uxType: EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER})
}

export const logAddSurveySuccess = () => {
  updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.SURVEY, EnumActionNameLevel3.SUCCESS);
  updateWidgetHistory('');
  sendLoggingInformation({uxType: EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER})
}


export {logEditListingSuccess,logLimitListingCategory, logLimitListingSubCategory, logLimitListingAccount,logEditListingSubmit, logPermissionLocationAllow, logPermissionLocationDeny, logScreenLoad,logAddListingSubmit, logAddListingSuccess, logApplyCvSuccess};